import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a className="sel" href="/lieky/">
                    Lieky
                  </a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/lieky/" className="sel">
              {" "}
              &gt; Lieky
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="drugs">
              <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_PLUS_1mg_left.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> PLUS <br />1 mg/50 mg/ml nosový roztokový sprej</p>
                    <div className="desc">
                    RÝCHLO UVOLŇUJE UPCHATÝ NOS. URÝCHĽUJE HOJENIE PORANENEJ NOSOVEJ SLIZNICE.
                    </div>
                    <a href="/lieky/olynth_plus_01/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_PLUS_0.5mg_left.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> PLUS <br />0,5 mg/50 mg/ml nosový roztokový sprej</p>
                    <div className="desc">
                    RÝCHLO UVOLŇUJE UPCHATÝ NOS U DETI OD 2 DO 6 ROKOV. URÝCHĽUJE HOJENIE PORANENEJ NOSOVEJ SLIZNICE.
                    </div>
                    <a href="/lieky/olynth_plus_05/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_HA_0.1 perc_2023_left.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                    OLYNTH<sup>®</sup> HA 0,1 % nosový roztokový sprej</p>
                    <div className="desc">
                    RÝCHLA ÚĽAVA OD NÁDCHY. ZVLHČUJE NOSOVÚ SLIZNICU VĎAKA TROM ZVLHČUJÚCIM LÁTKAM. 
                    </div>
                    <a href="/lieky/olynth_ha_01/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_HA_0.05 perc_2023_left.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                    OLYNTH<sup>®</sup> HA 0,05 % nosový roztokový sprej</p>
                    <div className="desc">
                    RÝCHLA ÚĽAVA OD  NÁDCHY
                    U DETÍ OD 2 DO 6 ROKOV. ZVLHČUJE NOSOVÚ SLIZNICU VĎAKA TROM ZVLHČUJÚCIM LÁTKAM. 
                    </div>
                    <a href="/lieky/olynth_ha_05/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_0.1_2023_Left_NEW.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                    OLYNTH<sup>®</sup> 0,1 % nosový roztokový sprej
                    </p>
                    <div className="desc">RÝCHLA ÚĽAVA OD NÁDCHY.</div>
                    <a href="/lieky/olynth_01/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_Olynth_0.05_2023_Left_NEW.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                    OLYNTH<sup>®</sup> 0,05 % nosový roztokový sprej
                    </p>
                    <div className="desc">
                    RÝCHLA ÚĽAVA OD NÁDCHY <br />U DETÍ OD 2 DO 6 ROKOV.
                    </div>
                    <a href="/lieky/olynth_05/" className="more">
                      VIAC
                    </a>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/nadcha/ako_si_spravne_cistit_nos/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/jak_prawidlowo_oczyszczac_nos.png)"}} />
                    </div>
                    <div className="c2">
                      Ako si správne čistiť nos pri nádche?{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/nadcha/spoznajte_typy_nadchy/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png)"}} />
                    </div>
                    <div className="c2">Spoznajte typy nádchy </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
             
              <a href="/lieky/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
